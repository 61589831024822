
























































import { Vue, Component } from 'vue-property-decorator'
import reportService, { FlexDividend } from '@/services/reportService'
import utility from '@/common/utility'
import { Sort } from '@/services/data'
import SearchFilter from '@/components/search-filter.vue'
import SortHeader from '@/components/SortHeader.vue'

let pageTrades: FlexDividend[] = []

@Component({ components: { SortHeader, SearchFilter } })
export default class Trades extends Vue {
  dividends: FlexDividend[] = []
  selectedSymbols = new Array<string>()
  symbols: string[] = []
  isLoading = false

  async mounted () {
    await this.loadData(false)
  }

  async loadData (forceUpdate: boolean) {
    const data = await reportService.getDividends(forceUpdate)
    if (data.Error) {
      await this.$alert(data.Error)
      return
    }
    pageTrades = data.Result
    this.symbols = utility.getDistinct(data.Result.map(p => utility.getSymbolDisplay(p.SymbolName, p.Currency)))
    this.dividends = utility.freezeArrayItems(pageTrades)
  }

  sortSymbol (direction: 'Asc' | 'Desc') {
    utility.sortByFiled(this.dividends, direction, p => p.SymbolName)
  }

  private filterSymbols () {
    const filter = pageTrades.filter(p => this.selectedSymbols.length === 0 || this.selectedSymbols.find(q => utility.iSameSymbol(q, p.SymbolName, p.Currency)))
    this.dividends = utility.freezeArrayItems(filter)
  }
}
