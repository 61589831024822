







import { Component, Vue, Prop } from 'vue-property-decorator'
import utility from '@/common/utility'

@Component
export default class SortHeader extends Vue {
  name = 'SortHeader'
  currentDirection: 'Asc' | 'Desc' = null

  @Prop() column: string
  @Prop() dataType: 'date' | 'number'
  @Prop() sortTarget: any[]

  private sortItems (direction: 'Asc' | 'Desc') {
    this.currentDirection = direction
    const sort = (a: any, b: any): number => {
      const v = direction === 'Asc' ? 1 : -1
      const left = this.getByKey(a)
      const right = this.getByKey(b)
      if (left === right) return 0
      return left > right ? v : v * -1
    }
    this.sortTarget.sort(sort)
  }

  private getByKey (a: any): any {
    if (this.dataType === 'date') {
      return utility.parseDate(a[this.column])
    }
    return a[this.column]
  }
}
