



















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

export interface SelectItem {
  item: string;
  selected: boolean;
}

@Component
export default class SearchFilter extends Vue {
  displayItems: SelectItem[] = []
  selectedAll = false
  searchText: string = null

  @Prop() listItems: string[]
  @Prop() value: string[]

  mounted () {
    this.displayItems = this.listItems.filter(p => !!p).map(p => {
      return { item: p, selected: this.value.indexOf(p) >= 0 }
    })
  }

  sort (direction: string) {
    this.$emit('sort', direction)
  }

  selectAllChanged () {
    for (const item of this.displayItems) { item.selected = this.selectedAll }
    this.$emit('input', this.displayItems.filter(p => p.selected).map(p => p.item))
    this.$emit('change')
  }

  itemSelectChanged () {
    this.$emit('input', this.displayItems.filter(p => p.selected).map(p => p.item))
    this.$emit('change')
  }

  @Watch('listItems')
  onListItemsChanged (v: string[]) {
    this.displayItems = this.listItems.filter(p => !!p).map(p => {
      return { item: p, selected: this.value.indexOf(p) >= 0 }
    })
  }

  @Watch('value')
  OnValueChanged (v: string[]) {
    if ((v == null || v.length === 0) && (this.displayItems == null || this.displayItems.length === 0)) return
    const selected = this.displayItems.filter(p => p.selected).map(p => p.item)
    if (selected.length === v.length && selected.every(p => v.indexOf(p) >= 0)) {
      return
    }
    this.displayItems = this.listItems.map(p => {
      return { item: p, selected: v.indexOf(p) >= 0 }
    })
  }
}
