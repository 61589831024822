import {
  ApiResult
} from '@/services/data'
import ServiceBase from '@/services/serviceBase'
import utility from '@/common/utility'

export interface FlexTrade {
  SymbolName: string;
  LocalSymbol: string;
  AccountId: string;
  SecType: string;
  TradeDate: string;
  Quantity: number;
  TradePrice: number;
  IbCommission: number;
  Strike: string;
  Right: string;
  Expiry: string;
  BuySell: string;
  Currency: string;
  Rate: number;
  LastUpdated: string;
  Multiplier: number;
  IsExercised: boolean;
  IsExp: boolean;
}

export interface FlexDividend {
  SymbolName: string;
  AccountId: string;
  SettleDate: string;
  Amount: number;
  Currency: string;
  Rate: number;
  LastUpdated: string;
}

export interface BaseDayProfit {
  TotalRealized?: number;
  Day: string;
  Realized: number;
  Unrealized: number;
  Dividend?: number;
  RealizedDesc?: string;
  UnrealizedDesc?: string;
}

export interface ProfitCalc {
  Total: { Count: number; Realized: number; Unrealized: number };
  Profits: SymbolProfit[];
}

export interface SymbolProfit {
  Symbol: string;
  DayProfit: BaseDayProfit[];
}

export interface DayProfitResult {
  Option: ProfitCalc;
  Stock: ProfitCalc;
  Dividend: FlexDividend[];
}

export default {
  async getTrades (type: string, forceUpdate: boolean, calcExercised = false): Promise<ApiResult<FlexTrade[]>> {
    const trades = await ServiceBase.tryPostResult<FlexTrade[]>('/Report/GetTrades', {
      ContractType: [type],
      ForceUpdate: forceUpdate,
      CalcExercised: calcExercised
    })
    return trades
  },
  async getDayProfits (symbols: string[], start: string, end: string, contractType?: string[], includeFee?: boolean): Promise<ApiResult<DayProfitResult>> {
    const trades = await ServiceBase.tryPostResult<DayProfitResult>('/Report/GetDayProfits', {
      Symbol: utility.parseSymbols(symbols),
      Start: start,
      End: end,
      ContractType: contractType,
      IncludeFee: includeFee
    })
    return trades
  },
  async getSingleDayProfits (day: string, contractType: string): Promise<ApiResult<FlexTrade[]>> {
    const trades = await ServiceBase.tryPostResult<FlexTrade[]>('/Report/getDayTradeDetail', {
      Day: day,
      ContractType: contractType
    })
    return trades
  },
  async uploadExcel (file: File, excelType: string) {
    return await ServiceBase.tryUploadResult<boolean>('/Report/UploadExcel', {
      file: file,
      excelType: excelType
    })
  },
  async downloadSample (name: string) {
    return await ServiceBase.tryDownload('/Report/downloadSample', {
      Name: name
    })
  },
  async download (type: string) {
    return await ServiceBase.tryDownload('/Report/download', {
      ContractType: [type]
    })
  },
  async getLastSyncDate () {
    const desc = await ServiceBase.tryPostResult<string>('/Report/getLastSyncDate')
    return desc.Result
  },
  async getDividends (forceUpdate: boolean): Promise<ApiResult<FlexDividend[]>> {
    const trades = await ServiceBase.tryPostResult<FlexDividend[]>('/Report/GetDividends', {
      ForceUpdate: forceUpdate
    })
    return trades
  }
}
